/* src/components/SignUp.css */
.contact-us{
  padding-top: 35px;
}

@keyframes centerOut {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .contact-us.animate-center-out {
    animation: centerOut 0.7s ease-in-out forwards;
    opacity: 0; /* Start with invisible state */
    transform: scale(0.5); /* Start with smaller scale */
    margin: 0 auto;
    background-color: #fff;
    border-radius: 0px;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    .contact-us {
      max-width: 100%;
      padding-top: 20px;

    }

  }
