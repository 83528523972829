/* Ensure the header stays fixed at the top */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3000;
  background-color: #007bff; /* Adjust this to your desired header color */
  padding: 10px 15px; /* Adjust padding as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}

/* Ensure the slide-in menu opens without affecting the header */
.slide-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
  z-index: 2000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(8px); /* Apply the blur effect */
  z-index: 998; /* Make sure it's behind the slide menu but above other content */
  pointer-events: auto; /* Allow clicks on this element */
}

/* When the menu is open */
.slide-menu.open {
  transform: translateX(0);
}

/* Navbar does not expand down */
.navbar-collapse {
  display: none; /* Prevent auto-expansion */
}

/* Toggler button for menu */
.navbar-toggler {
  background-color: transparent; /* Remove blue background from the toggler */
  border: none;
  outline: none;
}

/* Make sure the three-line menu icon is visible */
.navbar-toggler-icon {
  background-color: #007bff; /* Or any color you want for the menu icon */
  border-radius: 4px;
  padding: 5px;
}
.nav-link {
  border-radius: 10px;
}

.nav-link:hover {
  background-color: #0056b3;
}

/* Close button styling */
.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  float: right;
  margin-right: 10px;
}

/* Styling for the slide-in menu links */
.slide-menu-list {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.slide-menu-list li {
  margin-bottom: 15px;
}

.slide-menu-list li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  display: block;
  padding: 10px 0;
}

.custom-redeem-link {
  padding: 5px 10px; /* Adds padding around the text */
  border-radius: 4px; /* Rounds the corners slightly to make it more square */
  color: white; /* Ensures the text color is white */
  font-weight: bold; /* Makes the text bold */
  text-align: center; /* Centers the text */
}



@media (max-width: 768px) {
  .slide-menu {
    width: 75%;
  }

  .navbar {
    height: 8%;
  }

  .navbar-toggler {
    margin-right: 10px;
  }

  .slide-menu-list li a {
    font-size: 18px;
  }

  .close-button {
    margin-top: 15px;
    margin-right: 15px;
  }
}
