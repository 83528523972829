.container-terms {
  padding: 15px 15px;
  background-color: white;
}

.terms-us-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.terms-us-subtitle {
  font-size: 2rem;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #333;
}

.terms-us-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #555;
}

.terms-us-list {
  list-style-type: disc;
  margin-left: 20px;
}

.terms-us-list li {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #555;
}

.terms {
  box-shadow: none;
  margin: 0 auto;
  border-radius: 0px;
}


@keyframes centerOut {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.terms.animate-center-out {
  animation: centerOut 0.7s ease-in-out forwards;
  opacity: 0; /* Start with invisible state */
  transform: scale(0.5); /* Start with smaller scale */
  margin: 0 auto;
  background-color: white;
  border-radius: 0px;
  box-shadow: none;
}

@media (max-width: 767px) {
  .terms {
    max-width: 99%;
  }
}

@media (max-width: 768px) {
  .container-terms{
    padding: 0px 5px;
  }
  
  .container-terms h1{
    font-size: 1.5rem;
  }

  .container-terms h2{
    font-size: 1rem;
  }
  .container-terms p{
    font-size: 15px;

  }

}