.history-container {
  margin-top: 50px;
}

.table {
    font-size: 16px;
  }
  
  .table thead {
    background-color: #007bff;
    color: white;
  }

  tr {
    height: 60px;
  }

  td {
    padding: 20px;
    text-align: center;   
  }
  
  .btn.active {
    background-color: #007bff;
    color: white;
  }
  
  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #333;
  }
  
  @media (max-width: 768px) {

    .table-responsive table{
      font-size: 9px;
      border-collapse: collapse; /* Remove gaps between cells */
      padding: 5px;
    }
    .table-responsive td {
      font-size: 7px;
      text-align: left;   
      padding: 5px;
      margin: 0px;
    }
    td {
      padding: 5px;
    }
    .thead-dark tr{
      height: 20px;
    }

    .thead-dark th {
      font-size: 9px;
      text-align: center;
    }


    .history-body tr {
      height: 40px;
    }

    .history-container button {
      font-size: 10px;
    }
  }