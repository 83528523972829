.password-field {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .password-toggle {
    position: absolute;
    right: 1rem;
    top: 70%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
  }
  