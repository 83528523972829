.cpx-research-frame {
    padding-top: 35px;
    padding-left: 10%;
    padding-right: 10%;
}


@media (max-width: 768px) {

    .cpx-research-frame {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }

    
}