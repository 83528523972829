.threejs-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background: radial-gradient(circle, #1d1f21, #0f0f0f);
  overflow: hidden;
}

canvas {
  display: block; /* Remove extra margins from canvas */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
}

.overlay h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.overlay p {
  font-size: 1.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
