.task-card {
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    transition: border 0.3s ease-in-out;

  }



  
  .task-image {
    object-fit: cover;
    width: 100%;
    height: 300px; /* Adjust the height as needed */
  }
  

.task-card.animate-slide {
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.task-card:nth-child(2) {
  animation-delay: 0.5s; /* Delay for second card */
}

.task-card:nth-child(3) {
  animation-delay: 1s; /* Delay for third card */
}

@media (max-width: 768px) {

  .task-card {
    border-radius: 15px;
    height: 100%;

  }
 
  .task-image {
    height: 250px;
    width: 100%;
  }

  .task-card .card_prime {
  margin-bottom : 10%;
  }

}
