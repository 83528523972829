.offer-card {
   margin: 0px 0px;
    padding: 0px 0px;
    width: 200px;
    display: flex;
    height: 100%; 
  }

  .modal-bitlabs-offer .cuan-amount{
    font-size: 1.3rem;


  }


  
  .modal img {
    border-radius: 8px;
  }

  .play-overlay {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the play button */
    opacity: 0;
    pointer-events: none; /* Disable interaction when not hovering */
    transition: opacity 0.3s ease-in-out;
  }
  
  .play-btn {
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    padding: 15px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  /* The overlay that blurs the card content */
  .offer-card:hover img {
    filter: blur(5px); /* Apply blur effect */
    transition: filter 0.3s ease; /* Smooth blur transition */
  }
  
  .offer-card:hover .play-overlay {
    opacity: 1;
    pointer-events: auto; /* Enable interaction when hovered */
  }
  
  /* Optional: Add smooth zoom effect to image on hover */
  .offer-card:hover img {
    transform: scale(1.1); /* Zoom effect */
    transition: transform 0.3s ease;
  }
  
  .title-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 768px) {
    .offer-card{ 
      max-width: 130px;
      max-height: 200px;  
      margin: 0px 0px;
      padding: 0px 0px;
      font-size: 10px;
      display: flex;

    }


    .offer-card img {
      height: 100%;
      border-radius: 0px;
    }

    .offer-card .text-center {
      font-size: 0.7rem;
    }
    .modal img {
      border-radius: 4px;
      max-height: 120px;
      max-width: 120px;
    }

    .modal-bitlabs-offer {
      font-size: 0.8rem;
    }

    .modal-bitlabs-offer .header-modal{
      font-size: 1.2rem;
    }

    .modal-bitlabs-offer h5 {
      font-size: 1rem;
    }

    .modal-bitlabs-offer .cuan-amount{
      font-size: 1rem;
    }

    
  }

  