.payment-method-card {
  cursor: pointer;
  margin-bottom: 20px;
}

.payment-method-image {
  height: 100px;
  object-fit: contain;
  padding: 10px;
}

.payment-method-card:hover {
  border: 2px solid #007bff;
}

.card-title {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 10px;
}

.ml-2 {
  margin-left: 10px;
}
