/* Card Container */
.offer-card-surveys {
  padding: 10px;
  width: 200px;
  margin: 0 10px;
  display: flex;
  height: 100%;
  border: 2px solid #ddd; /* Remove quotes around the value */
  transition: border 0.3s ease-in-out; /* Remove quotes */
  overflow: hidden;
  position: relative; /* Necessary for .play-overlay positioning */
  border-radius: 15px;
}

/* Play Button Overlay */
.play-overlay {
  position: absolute;
  top: 50%; /* Center vertically within the container */
  left: 50%; /* Center horizontally within the container */
  transform: translate(-50%, -50%); /* Perfect centering */
  opacity: 0;
  pointer-events: none; /* Disable interaction when not hovering */
  transition: opacity 0.3s ease-in-out;
}

/* Play Button Styling */
.play-btn {
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 15px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Blur Effect on Hover */
.offer-card-surveys:hover img {
  filter: blur(5px); /* Apply blur effect */
  transform: scale(1.1); /* Smooth zoom effect */
  transition: transform 0.3s ease, filter 0.3s ease; /* Combine transitions for smoothness */
}

/* Make Play Button Visible on Hover */
.offer-card-surveys:hover .play-overlay {
  opacity: 1; /* Make it visible */
  pointer-events: auto; /* Enable interaction */
}



.custom-btn {
    background-color: #007bff;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 10px; /* Adjust for rounded corners if needed */
    padding: 0.375rem 0.75rem; /* Adjust padding as necessary */
    transition: background-color 0.3s ease;
}

.custom-btn:hover {
    background-color: #0056b3; /* Darker shade for hover effect */
    color: white;
    text-decoration: none;
}

.custom-btn:focus, .custom-btn:active {
    box-shadow: none; /* Remove default focus outline if needed */
}


@media (max-width: 768px) {
  .offer-card-surveys {
    max-width: 150px; /* Ensure maximum width is 150px */
    width: 100%; /* Allow card to adapt to available width */
    padding: 5px; /* Add some inner spacing */
    margin: 0 3px; /* Small horizontal margin for spacing */
    height: auto; /* Let the height adjust dynamically */
  }
}
