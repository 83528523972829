/* Footer Container */
footer {
  background-color: #0c253f;
  padding: 1rem 2rem;
  text-align: center;
  color: #fff;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

/* Footer Sections */
.footer-section {
  flex: 1;
  margin: 0 10px;
  min-width: 150px;
}

.footer-sections-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.footer-section h4 {
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: #ffcc00;
}

/* Language Section */
.language img {
  width: 20px;
  margin-right: 8px;
}

.language select {
  padding: 5px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.language select:focus {
  outline: none;
}

/* Social Media Icons */
.footer-section ul li a i {
  font-size: 20px;
}

.footer-section ul li a i:hover {
  color: #ffcc00;
}

/* App Links */
.app-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 5px; /* Space between items */
}

.app-links li {
  display: flex;
  align-items: center;
}

.app-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
}

.app-link .icon_media {
  width: 25px; /* Adjust size as needed */
  height: auto;
  margin-right: 10px; /* Space between image and text */
}

.app-link .icon_media_app {
  width: 25px; /* Adjust size as needed */
  height: auto;
}

.app-link span {
  font-size: 16px; /* Adjust text size as needed */
}

.app-link:hover {
  color: #007bff; /* Change text color on hover, adjust as needed */
}


/* Footer Bottom */
.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
  margin-top: 20px;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-bottom p {
  margin: 5px 0;
}

.chat-live-crisp h3 {
  font-size: 25px;
}

/* Responsive Adjustments */
/* Mobile View - Specific Update */
@media (max-width: 768px) {
  .footer-sections-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns for mobile view */
    gap: 15px; /* Space between columns */
    width: 100%;
  }

  .footer-section {
    margin-right: 0;
    width: 100%;
    text-align: center;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    text-align: center;
    padding-bottom: 60px;
  }

  .footer-section h4 {
    font-size: 15px;
  }

  .chat-live-crisp h3 {
    font-size: 18px;
  }

}
